* {
  box-sizing: border-box; }

html {
  font-size: 16px; }
  @media screen and (max-width: 900px) {
    html {
      font-size: 14px; } }
  @media screen and (max-width: 500px) {
    html {
      font-size: 12px; } }

body {
  font-family: "Open Sans", sans-serif;
  max-width: 1300px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  margin-left: auto;
  margin-right: auto;
  margin-top: 0; }

button {
  all: unset;
  color: blue;
  -webkit-text-fill-color: blue; }

button:focus {
  outline: orange 5px auto; }

.flex-h-center {
  position: flex;
  justify-content: center; }

.font-white {
  color: white; }

.margin-none {
  margin: 0; }

.margin-sm {
  margin: 0.25rem; }
  .margin-sm-l {
    margin-left: 0.25rem; }
  .margin-sm-r {
    margin-right: 0.25rem; }
  .margin-sm-top {
    margin-top: 0.25rem; }
  .margin-sm-bottom {
    margin-bottom: 0.25rem; }

.margin-md {
  margin: 0.75rem; }
  .margin-md-l {
    margin-left: 0.75rem; }
  .margin-md-r {
    margin-right: 0.75rem; }
  .margin-md-top {
    margin-top: 0.75rem; }
  .margin-md-bottom {
    margin-bottom: 0.75rem; }

.margin-lg {
  margin: 1rem; }
  .margin-lg-l {
    margin-left: 1rem; }
  .margin-lg-r {
    margin-right: 1rem; }
  .margin-lg-top {
    margin-top: 1rem; }
  .margin-lg-bottom {
    margin-bottom: 1rem; }

.padding-sm {
  padding: 0.25rem; }
  .padding-sm-l {
    padding-left: 0.25rem; }
  .padding-sm-r {
    padding-right: 0.25rem; }
  .padding-sm-top {
    padding-top: 0.25rem; }
  .padding-sm-bottom {
    padding-bottom: 0.25rem; }

.padding-md {
  padding: 0.75rem; }
  .padding-md-l {
    padding-left: 0.75rem; }
  .padding-md-r {
    padding-right: 0.75rem; }
  .padding-md-top {
    padding-top: 0.75rem; }
  .padding-md-bottom {
    padding-bottom: 0.75rem; }

.padding-lg {
  padding: 1rem; }
  .padding-lg-l {
    padding-left: 1rem; }
  .padding-lg-r {
    padding-right: 1rem; }
  .padding-lg-top {
    padding-top: 1rem; }
  .padding-lg-bottom {
    padding-bottom: 1rem; }

.padding-xlg {
  padding: 3rem; }

@media screen and (max-width: 400px) {
  .hide-sm {
    display: none; } }

.full-width {
  width: 100%; }

.fade-enter {
  opacity: 0; }

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms; }

#root {
  position: relative; }

.Button {
  text-decoration: none; }
  .Button-sm {
    font-size: 0.7rem;
    padding-top: 0.25rem;
    padding-right: 0.5rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    border-radius: 2px; }
  .Button-on-light-bg {
    color: #2f3c4c;
    border: 1px solid #2f3c4c;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    transition: all 0.4s; }
    .Button-on-light-bg:hover {
      transform: translateY(1px);
      box-shadow: none; }
    .Button-on-light-bg:active {
      box-shadow: inset; }

.ContactForm {
  position: relative;
  margin-top: 6rem;
  margin-right: auto;
  margin-bottom: 18rem;
  margin-left: auto;
  width: 75%;
  max-width: 750px;
  justify-content: space-between;
  padding: 2rem 3rem 0 3rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  background-image: linear-gradient(rgba(111, 207, 151, 0.5), rgba(111, 207, 151, 0.5)), url("../../static/double-bubble-outline.png");
  border: 1px solid #6fcf97; }
  .ContactForm__fields-container {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 1110px) {
      .ContactForm__fields-container {
        flex-direction: column;
        align-items: center; } }
  .ContactForm__field-group-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%; }
    @media screen and (max-width: 1110px) {
      .ContactForm__field-group-1 {
        width: 80%; } }
    @media screen and (max-width: 600px) {
      .ContactForm__field-group-1 {
        width: 100%; } }
  .ContactForm__field-group-2 {
    width: 60%; }
    @media screen and (max-width: 1110px) {
      .ContactForm__field-group-2 {
        width: 80%; } }
    @media screen and (max-width: 600px) {
      .ContactForm__field-group-2 {
        width: 100%; } }
  .ContactForm__button-container {
    display: flex;
    justify-content: center;
    padding-top: 2rem; }
  .ContactForm button {
    -webkit-text-fill-color: white;
    vertical-align: middle; }

.MuiFormControl-root.MuiTextField-root {
  margin-top: 0; }
  @media screen and (max-width: 1110px) {
    .MuiFormControl-root.MuiTextField-root {
      width: 100%; } }

.MuiFormHelperText-root,
.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-marginDense {
  background: rgba(111, 207, 151, 0.5);
  margin: 0; }

.ContactForm__footer {
  margin-left: auto;
  color: #d86613;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: -8px;
  right: 0; }
  .ContactForm__footer-text {
    margin-right: 0.5rem;
    font-size: 0.8rem; }
  .ContactForm__footer-icons {
    width: 2rem;
    height: 2rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    background-size: 100% 100%; }
  .ContactForm__footer .lambda {
    background-image: url("../../static/AWS-Lambda_light-bg.png"); }

.ContainerFlex {
  display: flex; }

.align-items-center {
  align-items: center; }

.flex-direction-column {
  flex-direction: column; }

.justify-content-center {
  justify-content: center; }

.justify-content-space-around {
  justify-content: space-around; }

.justify-content-space-between {
  justify-content: space-between; }

.justify-content-space-evenly {
  justify-content: space-evenly; }

.justify-content-flex-start {
  justify-content: flex-start; }

.justify-content-flex-end {
  justify-content: flex-end; }

@media screen and (max-width: 400px) {
  .justify-content-center-sm {
    justify-content: center; } }

@media screen and (max-width: 900px) {
  .justify-content-center-md {
    justify-content: center; } }

.DeveloperCard {
  max-width: 430px;
  max-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 250px;
  right: 3%;
  margin-left: auto;
  z-index: 1000;
  border: 4px solid #2f3c4c;
  box-shadow: 0 0 5px #6fcf97;
  border-radius: 2px;
  background: rgba(66, 71, 112, 0.6);
  opacity: 0.9;
  transform: scale(0.1);
  transform: skew(8deg) rotate(7deg);
  transition: all 0.2s ease-in-out; }
  @media (orientation: landscape) and (max-width: 830px) {
    .DeveloperCard {
      display: none; } }
  .DeveloperCard:hover {
    border: 4px solid rgba(111, 207, 151, 0.4);
    box-shadow: 0 0 5px #2f80ed;
    transform: scale(1.2) skew(0deg) translateX(-40px);
    opacity: 0.8;
    background: rgba(66, 71, 112, 0.9);
    transition: all 0.2s ease-in-out; }
  @media screen and (max-width: 495px) {
    .DeveloperCard {
      opacity: 0; } }
  .DeveloperCard__text-head {
    margin-right: 2rem;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.3); }
  .DeveloperCard__text {
    padding: 0.5rem 0.5rem;
    color: white;
    font-size: calc(13px + 0.16vw);
    line-height: 20px; }
  .DeveloperCard__minimize-icon {
    position: absolute;
    top: 0;
    right: 5px; }
    .DeveloperCard__minimize-icon:hover {
      cursor: pointer; }

.email-link {
  color: #2f80ed; }
  .email-link:hover {
    cursor: pointer; }

.devcard-nav {
  text-decoration: none;
  color: #2f80ed; }

.Footer {
  position: absolute;
  bottom: -12%;
  left: -3px;
  width: calc(100% + 5px);
  height: 300px;
  background-image: url("../../static/svgs/bottom.svg"); }

.GlobalNav {
  position: absolute;
  z-index: 9000;
  width: 100%;
  max-width: 1300px; }

.Header {
  background: #2f3c4c;
  height: 40vh;
  position: relative;
  margin-bottom: 9rem; }

.Mosaic__container {
  width: 100%;
  display: flex; }

.Header__mosaic {
  background: #2f3c4c;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: skewY(9deg);
  transform: skewY(7deg);
  -webkit-transform-origin: 0;
  transform-origin: 0;
  overflow: hidden; }
  .Header__mosaic__1 {
    flex-grow: 1;
    width: calc(100% / 3);
    align-self: flex-end;
    background-image: linear-gradient(to left, #1c3b65, rgba(45, 68, 86, 0.5)); }
  .Header__mosaic__2 {
    flex-grow: 0.7;
    width: calc(100% / 2);
    align-self: flex-start;
    background-image: linear-gradient(to right, #1c3b65, rgba(45, 68, 86, 0.5)); }
  .Header__mosaic__3 {
    display: inline-block;
    flex-grow: 1.5;
    height: 200px;
    width: calc(100% / 2);
    align-self: flex-start;
    background-image: linear-gradient(to right, #2d425f, rgba(41, 91, 76, 0.5)); }
  .Header__mosaic__4 {
    display: inline-block;
    flex-grow: 1.5;
    height: 200px;
    width: calc(100% / 2);
    align-self: flex-start;
    background-image: linear-gradient(to right, #2f3c4c, rgba(43, 151, 119, 0.1)); }

.Heading {
  text-transform: uppercase; }
  .Heading--1 {
    font-size: 1.8rem; }
  .Heading--2 {
    font-size: 1.5rem; }
  .Heading--3 {
    font-size: 1.2rem; }
  .Heading--4 {
    font-size: 1.1rem;
    font-weight: normal;
    margin-bottom: 0; }

.Page {
  padding-top: 0;
  padding-right: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 30vh;
  color: #424770;
  margin-left: auto;
  margin-right: auto; }
  .Page__left-shadow {
    position: absolute;
    bottom: -10%;
    left: 2px;
    height: 150%;
    width: 1px;
    box-shadow: -2px 0 3px 1px rgba(0, 0, 0, 0.25); }
  .Page__right-shadow {
    position: absolute;
    right: 2px;
    bottom: -10%;
    height: 100%;
    width: 1px;
    box-shadow: 2px 0 3px 1px rgba(0, 0, 0, 0.25); }

.Icon {
  height: 100%;
  width: auto; }
  .Icon--svg-link:hover * {
    fill: #2f80ed; }

.Logo--mountains {
  width: 120px;
  height: auto; }

.NavBar {
  min-width: 250px;
  display: flex;
  justify-content: center;
  margin-top: 21px; }
  .NavBar--link-container {
    display: flex;
    margin-right: 1rem;
    flex-direction: column;
    align-items: center; }
  .NavBar .NavBar--link {
    text-decoration: none;
    color: white;
    font-size: 1.1rem; }
    .NavBar .NavBar--link.active {
      text-decoration: none;
      color: #6fcf97; }
  .NavBar--link.active .NavBar--link-button-outer {
    background-color: #6fcf97;
    background-image: none;
    box-shadow: 0px 0px 8px 0px #6fcf97; }
  .NavBar--link.active .NavBar--link-button-inner {
    box-shadow: inset 3.57143px 3.57143px 5.35714px rgba(79, 98, 120, 0.3), inset 5.35714px 5.35714px 8.92857px rgba(213, 230, 245, 0.4); }
  .NavBar--link-button-inner {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(to bottom right, #e8f0f8, #bac5d2);
    box-shadow: 2px 1px 3px rgba(24, 55, 91, 0.2), inset -4px -3px 4px rgba(79, 98, 120, 0.2), inset 3px 3px 5px rgba(213, 230, 245, 0.4); }
  .NavBar--link-button-outer {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px;
    background-image: linear-gradient(to bottom right, #f8fbfd, #b4cbb2);
    box-shadow: 2px 1px 3px rgba(24, 55, 91, 0.2), inset -4px -3px 4px rgba(79, 98, 120, 0.2), inset 3px 3px 5px rgba(213, 230, 245, 0.4); }

.ProjectSection {
  padding-top: 0;
  padding-right: 1rem;
  padding-bottom: 0;
  padding-left: 1rem;
  margin-bottom: 7rem; }
  .ProjectSection__main {
    display: flex;
    justify-content: space-between;
    position: relative; }
    @media screen and (max-width: 900px) {
      .ProjectSection__main {
        flex-direction: column;
        flex-flow: column-reverse;
        justify-content: center;
        align-items: center; } }

.textScroller {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
  width: 45%;
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: 1;
  /* Firefox */ }
  @media screen and (max-width: 900px) {
    .textScroller {
      width: 100%; } }
  .textScroller--top-blind {
    position: sticky;
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.3));
    width: 100%;
    top: 0;
    height: 12%; }
  .textScroller--bottom-blind {
    position: sticky;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.3));
    width: 100%;
    bottom: -1px;
    height: 14%; }
  .textScroller::-webkit-scrollbar {
    background-color: white;
    width: 5px;
    border-radius: 10px;
    height: 80%; }
  .textScroller::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px; }
  .textScroller:hover::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.03);
    width: 5px;
    border-radius: 20px;
    height: 80%; }
  .textScroller:hover::-webkit-scrollbar-thumb {
    background-color: rgba(47, 128, 237, 0.3);
    border-radius: 20px; }
    .textScroller:hover::-webkit-scrollbar-thumb:hover {
      background-color: #2f80ed; }

.ProjectSection__text {
  width: 100%;
  font-size: 1rem;
  letter-spacing: 0.5px;
  line-height: 24px; }

.ProjectSection___screenshots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%; }
  .ProjectSection___screenshots-container:hover .screenshot-mobile {
    transform: none;
    opacity: 1; }
  @media screen and (max-width: 900px) {
    .ProjectSection___screenshots-container {
      width: 80%;
      margin: 5rem 0 2rem 0; } }
  .ProjectSection___screenshots-container:hover .screenshot-tablet {
    transform: rotate3d(0, 0, 0, 0deg) translate(5%);
    opacity: 1; }
  .ProjectSection___screenshots-container:hover .floating-icon-container {
    transform: translate(12%, -14vw);
    opacity: 1; }
    @media screen and (min-width: 1250px) {
      .ProjectSection___screenshots-container:hover .floating-icon-container {
        transform: translate(12%, -185px); } }
    @media screen and (max-width: 900px) {
      .ProjectSection___screenshots-container:hover .floating-icon-container {
        transform: translate(0, -18vw); } }

.floating-icon-container {
  transform: translate(12%, 0);
  display: flex;
  width: 50%;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s ease;
  position: absolute; }
  @media screen and (max-width: 900px) {
    .floating-icon-container {
      opacity: 1;
      transform: translate(0, -18vw); } }
  .floating-icon-container .icon-container {
    display: flex;
    align-items: center;
    height: 2vw;
    max-height: 20px;
    min-height: 15px;
    margin-top: 0;
    margin-right: calc(5px + 0.2vw);
    margin-bottom: 0;
    margin-left: calc(5px + 0.2vw); }

.screenshot-mobile-container {
  perspective: 1500px;
  width: 15%; }
  .screenshot-mobile-container .screenshot-mobile {
    transition: all 0.5s ease;
    opacity: 0;
    transform: translate(140%) rotate3d(0, 1, 0, -45deg);
    width: 100%;
    display: inline-block;
    box-shadow: 37.2px 62.5px 125px -25px rgba(50, 50, 93, 0.5), 22.3px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6); }
    @media screen and (max-width: 900px) {
      .screenshot-mobile-container .screenshot-mobile {
        transform: none;
        opacity: 1; } }

.screenshot-tablet-container {
  perspective: 2000px;
  width: 70%; }
  .screenshot-tablet-container .screenshot-tablet {
    transition: all 0.5s ease;
    transform: rotate3d(0, 1, 0, -35deg);
    display: inline-block;
    width: 100%;
    box-shadow: 37.2px 62.5px 125px -25px rgba(50, 50, 93, 0.5), 22.3px 37.5px 75px -37.5px rgba(0, 0, 0, 0.6); }
    @media screen and (max-width: 900px) {
      .screenshot-tablet-container .screenshot-tablet {
        transform: rotate3d(0, 0, 0, 0deg) translate(5%);
        opacity: 1; } }

.Resume-container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 2rem;
  margin-right: 0;
  margin-bottom: 8rem;
  margin-left: 0; }
